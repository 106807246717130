import React from 'react';

import Dashboard from './components/layout/dashboard';
import AuthGuard from './components/AuthGuard';

function App() {
  return (
    <AuthGuard>
      <Dashboard />
    </AuthGuard>
  );
}

export default App;
