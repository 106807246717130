import React from 'react';
import { Row, Col, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

class LoadingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      visible: false,
    });
    this.state = this.initialState();
  }

  render() {
    const antIcon = <Loading3QuartersOutlined style={{ fontSize: 30 }} spin />;
    return (
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        style={{ minHeight: 170 }}
      >
        <Col span={5} style={{ textAlign: 'center' }}>
          <Spin
            size="large"
            tip={this.props.tip || 'loading data'}
            indicator={antIcon}
          />
        </Col>
      </Row>
    );
  }
}
export default LoadingComponent;
