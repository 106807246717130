import React, { Suspense } from 'react';

import { observer, inject } from 'mobx-react';
import { Layout, Menu, Button, Col, Popover, Avatar } from 'antd';
import {
  HeartFilled,
  HomeOutlined,
  MenuOutlined,
  UserOutlined,
  TeamOutlined,
  TagOutlined,
  ProjectOutlined,
  FileTextOutlined,
  DotChartOutlined,
  BranchesOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';

import { Switch, Route, Link, withRouter } from 'react-router-dom';
import Loading from './../basic/Loading';
import routes from './dashboardRoutes';
import largeLogo from './../../assets/images/logo.svg';
import smallLogo from './../../assets/images/logo2.svg';

import './dashboard.css';
const { Header, Content, Footer, Sider } = Layout;

@observer
@inject('userStore', 'tokenStore', 'sectionsStore')
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => {
      return {
        collapsed: false,
        expandedWithToggler: true,
      };
    };
    this.state = this.initialState();
    this.onCollapse = (collapsed) => {
      this.setState({ collapsed });
    };
    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => {
        return { collapsed: !prevState.collapsed, expandedWithToggler: true };
      });
    };
    this.expandSidebar = () => {
      if (this.state.collapsed)
        this.setState({ collapsed: false, expandedWithToggler: false });
    };
    this.collapseSidebar = () => {
      if (!this.state.expandedWithToggler) this.setState({ collapsed: true });
    };
    this.logout = () => {
      Promise.all([
        this.props.userStore.clear(),
        this.props.tokenStore.clear(),
        this.props.sectionsStore.clear(),
      ]).then(() => {});
    };
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <style>{`
          .ant-menu-item-selected{
            background-color: #1f2533 !important;
          }
        `}</style>
        <Sider
          theme="dark"
          onMouseEnter={this.expandSidebar}
          onMouseLeave={this.collapseSidebar}
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          style={{ background: '#2d3546' }}
          width={240}
        >
          <div
            className="logo"
            style={{
              minHeight: 70,
              textAlign: 'center',
              paddingTop: 20,
              background: '#1f2532',
            }}
          >
            {this.state.collapsed ? (
              <img style={{ width: 45, marginBottom: 5 }} src={smallLogo} />
            ) : (
              <img style={{ width: 140 }} src={largeLogo} />
            )}
          </div>
          <Menu
            theme="dark"
            selectedKeys={[this.props.location.pathname]}
            mode="inline"
            style={{ background: '#2d3646', marginTop: 35 }}
          >
            <Menu.Item key="/home">
              <HomeOutlined />
              <span>Home Layout</span>
              <Link to="/home" />
            </Menu.Item>
            <Menu.Item key="/teammember">
              <TeamOutlined />
              <span>Team Member</span>
              <Link to="/teammember" />
            </Menu.Item>
            <Menu.Item key="/brand">
              <TagOutlined />
              <span>Brand</span>
              <Link to="/brand" />
            </Menu.Item>
            <Menu.Item key="/department">
              <BranchesOutlined />
              <span>Departments</span>
              <Link to="/department" />
            </Menu.Item>
            <Menu.Item key="/position">
              <ShareAltOutlined />
              <span>Positions</span>
              <Link to="/position" />
            </Menu.Item>
            <Menu.Item key="/work">
              <ProjectOutlined />
              <span>Work</span>
              <Link to="/work" />
            </Menu.Item>
            <Menu.Item key="/blog">
              <FileTextOutlined />
              <span>Blog</span>
              <Link to="/blog" />
            </Menu.Item>
            <Menu.Item key="/seo">
              <DotChartOutlined />
              <span>SEO</span>
              <Link to="/seo" />
            </Menu.Item>
            <Menu.Item key="/user">
              <UserOutlined />
              <span>User</span>
              <Link to="/user" />
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: 'inherit' }}
          >
            <Col span={1} style={{ textAlign: 'center' }}>
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: '#1f2532' }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>
            <Col offset={21} span={2}>
              <Popover
                content={
                  <a href="# " onClick={this.logout}>
                    Logout
                  </a>
                }
                trigger="hover"
                // visible={visible}
                // onVisibleChange={handleVisibleChange}
                arrowPointAtCenter={true}
                placement="bottomLeft"
              >
                <Avatar icon={<UserOutlined />} />
              </Popover>
            </Col>
          </Header>
          <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route, idx) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <span>Developed with </span>
            <HeartFilled style={{ color: 'red' }} /> By&nbsp;
            <a href="#" title="Lucid Source">
              Lucid Source
            </a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
