import React from 'react';
import Login from './login/index';
import { observer, inject } from 'mobx-react';
@observer
@inject('tokenStore')
class AuthGuard extends React.Component {
  render() {
    if (`${this.props.tokenStore.value}`.trim() != '') {
      return this.props.children;
    } else {
      return <Login to={this.props.children} />;
    }
  }
}

export default AuthGuard;
